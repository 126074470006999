import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    Button,
    Card,
    Col,
    Form,
    Row,
    Table,
} from 'react-bootstrap';
import AccordionHeader from '../../../components/AccordionHeader';
import AverageTrendLineChart from './AverageTrendLineChart';
import SensorReadingChart from './SensorReadingChart';
import { EtSummary, IrrigationCalculator, IrrigationRecommendations, IrrigationSpecs } from '../Cards';
import SettingsModal from './SettingsModal';
import Spinner from '../../../common/Spinner';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';

import {
    GET_PLACE_LINK_THING,
    GET_DEVICE_MOISTURE_CHART,
    GET_DEVICE_RAIN_CHART,
    GET_DEVICE_MOISTURE_AVERAGE_CHART,
    GET_PIVOT_CHART
} from '../../../GraphQL/Queries/Things';

import { UPDATE_PLACE_LINK_THING } from '../../../GraphQL/Mutations/Things';
import {
    UPDATE_PLACE_LINK_CROP,
    // ADD_PLACE_LINK_CROP
} from '../../../GraphQL/Mutations/Places';

import { PastData } from '../../../GraphQL/Queries/devices';

import './charts.scss';


const acreInches = '0.5';
const irrigatedAcres = '125.25';
const wellCapacity = '400';
const defaultSoilTexture = 'Silt Loam';

// const Calculator = React.memo(IrrigationCalculator);

const calculateDaysToApplyIrrigation = (data) => {
    if (
        data.irrigatedAcres === '0'
        || data.acreInches === '0'
        || data.wellCapacity === '0'
    ) {
        return '0.0';
    }
}

const watermarkTextureLookup = require('../Charts/data/waterMark.json');

const ChartComponent = (props) => {
    const {
        field,
        device,
        fieldPreview,
        refetchFieldSensorPreview,
        refreshDevices,
        cropYear,
    } = props;
    const deviceType = device.type || 'capacitance';
    const { addToast } = useToasts();

    const [selectedChart, setSelectedChart] = useState('sensorReading');
    const [rerunPreviews, setRerunPreviews] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [depthSensors, setDepthSensors] = useState(null);
    const [sensorSeries, setSensorSeries] = useState([]);
    const [pivotStatus, setPivotStatus] = useState(null);
    const [pivotTotals, setPivotTotals] = useState(null);
    const [averageSeries, setAverageSeries] = useState([]);
    const [specsOpen, setSpecsOpen] = useState(null);
    const [calcOpen, setCalcOpen] = useState(null);
    const [etsOpen, setEtsOpen] = useState(null);
    const [pivotOpen, setPivotOpen] = useState(null);
    const [irOpen, setIrOpen] = useState(null);
    const [plt, setPLT] = useState(null);
    const [instantiated, setInstantiated] = useState(false);
    const [previousMoistureData, setPreviousMoistureData] = useState(null);
    // const [ addPlaceLinkCrop ] = useMutation(ADD_PLACE_LINK_CROP);
    const [updatePlaceLinkCrop] = useMutation(UPDATE_PLACE_LINK_CROP);
    const [updatePlaceLinkThing] = useMutation(UPDATE_PLACE_LINK_THING);
    const [dataforAvg, setDataForAvg] = useState([]);
    const [probesToAverage, setProbesToAverage] = useState([]);
    const [
        getDeviceMoistureChart,
        { called: deviceMoistureCalled, data: deviceMoistureData }
    ] = useLazyQuery(GET_DEVICE_MOISTURE_CHART);

    const [
        getDeviceRainChart,
        { called: deviceRainCalled, data: deviceRainData }
    ] = useLazyQuery(GET_DEVICE_RAIN_CHART);

    const [
        getPivotChart,
        { called: pivotCalled, data: pivotData }
    ] = useLazyQuery(GET_PIVOT_CHART);

    const [
        getAverageMoistureChart,
        { called: averageMoistureCalled, data: averageMoistureData }
    ] = useLazyQuery(GET_DEVICE_MOISTURE_AVERAGE_CHART);

    const [
        things,
        { called: thingPastDataCalled, data: thingPastData }
    ] = useLazyQuery(PastData)

    const {
        loading: gettingPLT,
        error: errorGettingPLT,
        data: pltData,
        refetch: refetchPlaceLinkThing
    } = useQuery(
        GET_PLACE_LINK_THING,
        {
            variables: {
                placeGuid: field.guid,
                thingGuid: device.guid,
                linkGuid: device.links[0]['guid']
            },
            context: { service: 'things' }
        }
    );

    const [calculatorData, setCalculatorData] = useState({
        acreInches,
        daysToApplyIrrigation: calculateDaysToApplyIrrigation({ acreInches, irrigatedAcres, wellCapacity }),
        irrigatedAcres,
        wellCapacity,
    });

    const [tileToggle] = useState({
        etSummary: true,
        irrigationCalculator: false,
        irrigationRecommendations: true,
        irrigationSpecs: false,
    });

    const getSensorReadingData = useCallback(async () => {
        // const currentYear = new Date().getFullYear();
        // if (cropYear !== JSON.stringify(currentYear)) {
        // const startDate = new Date('2021/01/01');
        // console.log("DEVICE", device);
        let startDate = new Date('2021/01/01');
        if (cropYear === "2023") {
            switch (device.type) {
                case "Rain Gauge":
                    setSelectedChart('rain');
                    getDeviceRainChart({
                        variables: {
                            placeGuid: field.guid
                        },
                        context: {
                            service: 'things'
                        }
                    });
                    break;
                case "Pivot Monitor":
                    setSelectedChart('pivot');
                    getPivotChart({
                        variables: {
                            deviceGuid: device.guid,
                        },
                        context: {
                            service: 'things'
                        }
                    });
                    break;

                default:
                    getDeviceMoistureChart({
                        variables: {
                            deviceGuid: device.guid,
                            start: startDate.getTime(),
                            end: new Date().getTime()
                        },
                        context: {
                            service: 'things'
                        }
                    });
                    break;
            }
        } else {
            let whichValues;
            if (cropYear === "2022") {
                whichValues = {
                    deveui: device.deveui,
                    hasdataConnection: {
                        edge: {
                            enddate: 1671084000000,
                            AND: [
                                {
                                    startdate: 1641016800000
                                }
                            ]
                        }
                    }
                }
            }
            if (cropYear === "2021") {
                whichValues = {
                    deveui: device.deveui,
                    hasdataConnection: {
                        edge: {
                            enddate: 1641016799000,
                            AND: [
                                {
                                    startdate: 1609480801000
                                }
                            ]
                        }
                    }
                }
            }
            switch (device.type) {
                case "Rain Gauge":
                    setSelectedChart('rain');
                    things({
                        variables: {
                            where: whichValues
                        },
                        context: {
                            service: 'pastData'
                        }
                    });
                    break;
                case "Pivot Monitor":
                    setSelectedChart('pivot');
                    things({
                        variables: {
                            where: whichValues
                        },
                        context: {
                            service: 'pastData'
                        }
                    });
                    break;
                default:
                    things({
                        variables: {
                            where: whichValues
                        },
                        context: {
                            service: 'pastData'
                        }
                    });
                    break;
            }
        }

    }, [device, getDeviceMoistureChart, getDeviceRainChart, getPivotChart, things, field, cropYear])

    const handleFieldSettings = React.useCallback(async (data) => {
        const d = JSON.parse(JSON.stringify(data));
        const placeData = { ...d.place };
        const thingData = { ...d.thing };
        const linkData = { ...d.link };
        const cropData = { ...placeData.link }

        delete thingData['__typename'];
        delete placeData['__typename'];
        delete linkData['__typename'];

        const thing = {
            ...thingData,
        };

        delete thing.links;

        const link = {
            ...linkData,
            startDate: Number(linkData.startDate) || 0,
            fullSF: Number(linkData.fullSF),
            growthStage: linkData.growthStage || '',
            lat: Number(linkData.lat) || 0,
            lng: Number(linkData.lng) || 0,
            pinFileName: linkData.pinFileName || '',
            state: linkData.state || '',
            technician: linkData.technician || '',
            wiltSF: Number(linkData.wiltSF),
            oneFtSoilTexture: linkData.oneFtSoilTexture,
            twoFtSoilTexture: linkData.twoFtSoilTexture,
            threeFtSoilTexture: linkData.threeFtSoilTexture,
            probesToAverage: linkData.probesToAverage
        };

        const crop = JSON.parse(JSON.stringify(cropData));

        if (Object.keys(crop).length > 0) {
            const vars = {
                placeGuid: placeData.guid,
                cropGuid: crop.crop.guid,
                link: {
                    plantingDate: Number(crop.plantingDate),
                    currentGrowthStage: crop.currentGrowthStage,
                    maturity: crop.maturity,
                }
            }

            if (crop.guid) {
                vars.link.guid = crop.guid;
                await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

            } else {
                const response = await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

                const { data: { updatePlaceLinkCrop: { results } } } = response;

                if (results.length > 0) {
                    const newGuid = results[0];

                    placeData.link.crop.guid = newGuid;
                }
            }

        }

        const place = {
            ...placeData,
            lat: Number(placeData.lat) || 0,
            lng: Number(placeData.lng) || 0,
            pivotPassAmount: Number(placeData.pivotPassAmount) || 0,
            wellCapacity: Number(placeData.wellCapacity) || 0,
            acreInches: Number(placeData.acreInches) || 0,
            soilTexture: placeData.soilTexture || '',

        }

        delete place.link;
        delete place.fieldSize;
        delete place.et;
        delete place.endDate;
        delete place.startDate;
        delete place.soilTexture;
        delete place.link;

        if (!thingData.link) {
            thingData.links = [];
        }

        thingData.links[0] = link;

        const response = await updatePlaceLinkThing(
            {
                variables: {
                    place,
                    link,
                    thing
                },
                context: {
                    service: 'things'
                },
            }
        );

        const { data: { updatePlaceLinkThing: { ok } } } = response;

        // var ok = true;
        if (ok && ok === true) {
            addToast('Settings were successfully updated', {
                appearance: 'success',
                autoDismiss: true,
            });

            place.link = cropData;
            refetchPlaceLinkThing();
            getSensorReadingData();
            refetchFieldSensorPreview();
        } else {
            addToast('There was a problem saving the settings', {
                appearance: 'error',
            });
        }

    }, [updatePlaceLinkThing, updatePlaceLinkCrop, refetchPlaceLinkThing, refetchFieldSensorPreview, addToast, getSensorReadingData]);

    const handleDeviceSettings = React.useCallback(async (data) => {
        const d = JSON.parse(JSON.stringify(data));
        const placeData = { ...d.place };
        const thingData = { ...d.thing };
        const linkData = { ...d.link };
        const cropData = { ...placeData.link }

        delete thingData['__typename'];
        delete placeData['__typename'];
        delete linkData['__typename'];

        const thing = {
            ...thingData,
        };

        delete thing.links;

        const link = {
            ...linkData,
            startDate: Number(linkData.startDate) || 0,
            fullSF: Number(linkData.fullSF),
            growthStage: linkData.growthStage || '',
            lat: Number(linkData.lat) || 0,
            lng: Number(linkData.lng) || 0,
            pinFileName: linkData.pinFileName || '',
            state: linkData.state || '',
            technician: linkData.technician || '',
            wiltSF: Number(linkData.wiltSF),
            oneFtSoilTexture: linkData.oneFtSoilTexture,
            twoFtSoilTexture: linkData.twoFtSoilTexture,
            threeFtSoilTexture: linkData.threeFtSoilTexture,
        };

        const crop = JSON.parse(JSON.stringify(cropData));

        if (Object.keys(crop).length > 0) {
            const vars = {
                placeGuid: placeData.guid,
                cropGuid: crop.crop.guid,
                link: {
                    plantingDate: Number(crop.plantingDate),
                    currentGrowthStage: crop.currentGrowthStage,
                    maturity: crop.maturity,
                }
            }

            if (crop.guid) {
                vars.link.guid = crop.guid;
                await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

            } else {
                const response = await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

                const { data: { updatePlaceLinkCrop: { results } } } = response;

                if (results.length > 0) {
                    const newGuid = results[0];

                    placeData.link.crop.guid = newGuid;
                }
            }

        }

        const place = {
            ...placeData,
            lat: Number(placeData.lat) || 0,
            lng: Number(placeData.lng) || 0,
            pivotPassAmount: Number(placeData.pivotPassAmount) || 0,
            wellCapacity: Number(placeData.wellCapacity) || 0,
            acreInches: Number(placeData.acreInches) || 0,
        }

        delete place.link;
        delete place.link;
        delete place.fieldSize;
        delete place.et;
        delete place.wellCapacity;
        delete place.endDate;
        delete place.startDate;

        if (!thingData.link) {
            thingData.links = [];
        }

        thingData.links[0] = link;

        const response = await updatePlaceLinkThing(
            {
                variables: {
                    place,
                    link,
                    thing
                },
                context: {
                    service: 'things'
                },
            }
        );

        const { data: { updatePlaceLinkThing: { ok } } } = response;

        // var ok = true;
        if (ok && ok === true) {
            addToast('Settings were successfully updated', {
                appearance: 'success',
                autoDismiss: true,
            });

            place.link = cropData;
            refreshDevices();
            refetchPlaceLinkThing();
            refetchFieldSensorPreview();
            getSensorReadingData();
        } else {
            addToast('There was a problem saving the settings', {
                appearance: 'error',
            });
        }
    }, [updatePlaceLinkThing, updatePlaceLinkCrop, refetchPlaceLinkThing, refetchFieldSensorPreview, addToast, getSensorReadingData, refreshDevices])

    const handleChartSelect = (event) => {
        const value = event.target.value;
        setSelectedChart(value);
    }

    const handleEtsOpen = (card) => {
        if (card === null || etsOpen !== null) {
            setEtsOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setEtsOpen(cardName);
        }

        setIrOpen(null);
        setSpecsOpen(null);
        setCalcOpen(null);
    }

    const handlePivotOpen = (card) => {
        if (card === null || pivotOpen !== null) {
            setPivotOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setPivotOpen(cardName);
        }
    }

    const handleIrOpen = (card) => {
        if (card === null || irOpen !== null) {
            setIrOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setIrOpen(cardName);
        }

        setSpecsOpen(null);
        setCalcOpen(null);
    }

    const handleSpecsOpen = (card) => {
        if (card === null || specsOpen !== null) {
            setSpecsOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setSpecsOpen(cardName);
        }

        setEtsOpen(null);
        setIrOpen(null);
        setCalcOpen(null);

    }

    const handleCalcOpen = (card) => {
        if (card === null || calcOpen !== null) {
            setCalcOpen(null);
        } else {
            const cardName = card + '-' + field.guid.toString();
            setCalcOpen(cardName);
        }

        setEtsOpen(null);
        setIrOpen(null);

    }

    const handleCalculatorInputChange = (e, inputName) => {
        const { value } = e.target;
        const number = value.replace(/[^0-9.]/g, '');

        const updatedCalculatorData = {
            ...calculatorData,
            [inputName]: number,
        };

        const daysToApplyIrrigation = calculateDaysToApplyIrrigation(updatedCalculatorData);

        setCalculatorData({
            ...updatedCalculatorData,
            daysToApplyIrrigation,
        });
    }


    const getAverageData = (depths, rerun) => {
        if (cropYear === "2023") {
            // const d = new Date();
            // const year = d.getFullYear();
            // console.log("DEVICE", device);
            let startDate = new Date('2021/01/01');
            let probesToAverage = depths || [];
            // console.log("DEPTHS", depths);
            setRerunPreviews(rerun || false);

            if (!depths && fieldPreview !== null) {
                //
                // Get List of depth sensors
                const { devices } = fieldPreview;
                for (let i = 0; i < devices.length; i++) {
                    if (device.guid === devices[i].deviceGuid) {
                        probesToAverage = JSON.parse(devices[i].probesToAverage);
                    }
                }
            }

            if (probesToAverage === null) {
                probesToAverage = [];
            }

            getAverageMoistureChart({
                variables: {
                    deviceGuid: device.guid,
                    start: startDate.getTime(),
                    end: new Date().getTime(),
                    probesToAverage: probesToAverage
                },
                context: { service: 'things' }
            });
        } else {
            let deviceProbes = depths || [];
            setRerunPreviews(rerun || false);
            if (!depths && fieldPreview !== null) {
                const { devices } = fieldPreview;
                for (let i = 0; i < devices.length; i++) {
                    if (device.guid === devices[i].deviceGuid) {
                        deviceProbes = (JSON.parse(devices[i].probesToAverage));
                    }
                }
            }
            if (deviceProbes === null) {
                setProbesToAverage([]);
            } else {
                setProbesToAverage(deviceProbes)
            }
        }
    }

    const getAndStoreDepthSensors = (data) => {
        const sensorList = [];
        // console.log("DATA", data);
        for (let i = 0; i < data.length; i++) {
            const name = parseInt(data[i]['name']);
            if (!isNaN(name)) {
                sensorList.push(parseInt(name));
            }
        }

        setDepthSensors(sensorList);
    }

    function convertCbToPaw(soilTexture, sensorReading) {
        let pawRatio;

        const x = watermarkTextureLookup.filter(xx => (xx.textureName === soilTexture)); // capacity off of sand

        if (x.length) {
            const xx = x[0].watermarkTable.filter(yy => (yy.cb <= sensorReading)); // finding which ratio matches our reading the best  cd=centibars
            if (xx.length) {
                xx.sort((a, b) => b.cb - a.cb); // DESC
                pawRatio = xx[0].pawRatio;
            } else {
                x[0].watermarkTable.sort((a, b) => a.cb - b.cb); // ASC  if below the lowest reading, sort ASC use the lowest reading
                pawRatio = x[0].watermarkTable[0].pawRatio;
            }
        }
        return pawRatio;
    }

    //
    // PLT Set
    useEffect(() => {
        if (!gettingPLT && !errorGettingPLT) {
            const { getPlaceLinkThing: { results } } = pltData;

            if (results.length > 0) {
                const row = results[0];

                setPLT({
                    thing: row.thing,
                    place: row.place,
                    link: row.link
                });
            }
        }
    }, [gettingPLT, errorGettingPLT, pltData]);

    //
    // Sets Chart with past data for all devices
    useEffect(() => {
        if (thingPastDataCalled && thingPastData && pltData) {
            const { things } = thingPastData;
            console.log('line 709: ',things);
            if (things && things.length && things[0].hasdata.length) {
                const parsedData = JSON.parse(things[0].hasdata[0].data);
                if (device.deveui < 60000000 && device.deveui > 50000000) {
                    const m1 = [];
                    const m2 = [];
                    const m3 = [];
                    const m4 = [];
                    const m5 = [];
                    const m6 = [];
                    const newObj = [];
                    const avgData = [];
                    parsedData.forEach((x) => {
                        const epoch = x[0]
                        const depth1 = x[1]
                        const depth2 = x[2]
                        const depth3 = x[3]
                        const depth4 = x[4]
                        const depth5 = x[5]
                        const depth6 = x[6]
                        m1.push([epoch, depth1])
                        m2.push([epoch, depth2])
                        m3.push([epoch, depth3])
                        m4.push([epoch, depth4])
                        m5.push([epoch, depth5])
                        m6.push([epoch, depth6])
                    })
                    newObj.push({ "type": "spline", "name": "4\"", "data": m1 }, { "type": "spline", "name": "8\"", "data": m2 }, { "type": "spline", "name": "12\"", "data": m3 }, { "type": "spline", "name": "16\"", "data": m4 }, { "type": "spline", "name": "24\"", "data": m5 }, { "type": "spline", "name": "32\"", "data": m6 }, { "name": "Rain (Hourly)", "type": "spline", "data": [] }, { "name": "Rain (Daily)", "data": [], "type": "column", "yAxis": 1, "pointPlacement": -1.2 })
                    avgData.push({ "type": "spline", "name": "4\"", "data": m1 }, { "type": "spline", "name": "8\"", "data": m2 }, { "type": "spline", "name": "12\"", "data": m3 }, { "type": "spline", "name": "16\"", "data": m4 }, { "type": "spline", "name": "24\"", "data": m5 }, { "type": "spline", "name": "32\"", "data": m6 })
                    setDataForAvg([...avgData])
                    getAndStoreDepthSensors(newObj);
                    setSensorSeries([...newObj])
                }
                else if (device.deveui < 4000000 && device.deveui > 3000000) {
                    let wd6 = '';
                    let wd24 = '';
                    let wd32 = '';
                    if (pltData.getPlaceLinkThing.results[0].link.oneFtSoilTexture !== null) {
                        wd6 = pltData.getPlaceLinkThing.results[0].link.oneFtSoilTexture;
                    } else {
                        wd6 = defaultSoilTexture;
                    }
                    if (pltData.getPlaceLinkThing.results[0].link.twoFtSoilTexture !== null) {
                        wd24 = pltData.getPlaceLinkThing.results[0].link.twoFtSoilTexture;
                    } else {
                        wd24 = defaultSoilTexture;
                    }
                    if (pltData.getPlaceLinkThing.results[0].link.threeFtSoilTexture !== null) {
                        wd32 = pltData.getPlaceLinkThing.results[0].link.threeFtSoilTexture;
                    } else {
                        wd32 = defaultSoilTexture;
                    }
                    const m1 = [];
                    const m2 = [];
                    const m3 = [];
                    const m4 = [];
                    const newObj = [];
                    const avgData = [];
                    parsedData.forEach((x) => {
                        const epoch = x[0];
                        const depth1 = convertCbToPaw(wd6, x[1]) * 100;
                        const depth2 = convertCbToPaw(wd6, x[2]) * 100;
                        const depth3 = convertCbToPaw(wd24, x[3]) * 100;
                        const depth4 = convertCbToPaw(wd32, x[4]) * 100;
                        m1.push([epoch, depth1])
                        m2.push([epoch, depth2])
                        m3.push([epoch, depth3])
                        m4.push([epoch, depth4])
                    })
                    newObj.push({ "type": "spline", "name": "6\"", "data": m1 }, { "type": "spline", "name": "12\"", "data": m2 }, { "type": "spline", "name": "24\"", "data": m3 }, { "type": "spline", "name": "36\"", "data": m4 }, { "name": "Rain (Hourly)", "type": "spline", "data": [] }, { "name": "Rain (Daily)", "data": [], "type": "column", "yAxis": 1, "pointPlacement": -1.2 })
                    avgData.push({ "type": "spline", "name": "6\"", "data": m1 }, { "type": "spline", "name": "12\"", "data": m2 }, { "type": "spline", "name": "24\"", "data": m3 }, { "type": "spline", "name": "36\"", "data": m4 })
                    setDataForAvg([...avgData])
                    getAndStoreDepthSensors(newObj);
                    setSensorSeries([...newObj])
                }
                else if (device.deveui < 7000000 && device.deveui > 6000000) {
                    const datesArray = [];
                    const pivotStatusMessages = [];
                    const epochHour = 60 * 60 * 1000;
                    const epochDay = epochHour * 24;
                    const returnObject = { pivotTotals: [], highChartData: '[]' };

                    let prevStatus = "";

                    parsedData.forEach((x) => {
                        pivotStatusMessages.push({ "status": x[1], "epoch": x[0], "occured_at": x[2] })
                    })

                    let previousDate = new Date(pivotStatusMessages[0].occured_at)

                    for (let index = 0; index < pivotStatusMessages.length; index++) {
                        const element = pivotStatusMessages[index];
                        const epoch = element.epoch;
                        const occuredAtDate = new Date(element.occured_at)
                        const thisRuntime = epoch - previousDate;
                        const event = {
                            epoch,
                            dateTime: `${occuredAtDate.toLocaleDateString()} ${occuredAtDate.toLocaleTimeString()}`,
                            hoursForward: 0,
                            hoursReverse: 0,
                            hoursOff: 0,
                        };

                        if (epoch > 100000) {
                            if (thisRuntime) {
                                let currStatus = '';
                                if (element.status === 'fault') {
                                    currStatus = 'fault';
                                } else if (element.status === 'forward') {
                                    currStatus = 'turn on (forward)';
                                } else if (element.status === 'reverse') {
                                    currStatus = 'turn on (reverse)';
                                } else {
                                    currStatus = 'turn off'
                                }
                                event.event = currStatus;

                                if (currStatus !== prevStatus) {
                                    switch (prevStatus) {
                                        case 'turn off':
                                            event.hoursOff = thisRuntime / epochHour;
                                            break;
                                        case 'turn on (forward)':
                                            event.hoursForward = thisRuntime / epochHour;
                                            break;
                                        case 'turn on (reverse)':
                                            event.hoursReverse = thisRuntime / epochHour;
                                            break;

                                        default:
                                            break;
                                    }
                                    returnObject.pivotTotals.push(event);
                                    prevStatus = currStatus;
                                    previousDate = epoch;
                                }
                            }
                        }
                    }

                    returnObject.pivotTotals.sort((a, b) => (a.epoch - b.epoch));
                    prevStatus = 'turn off';
                    for (let index = 0; index < returnObject.pivotTotals.length; index++) {
                        const event = returnObject.pivotTotals[index];
                        if (event.event !== prevStatus) {
                            if (event.event !== 'turn off') {
                                previousDate = event.epoch;
                            } else {
                                const midnightDate = new Date(previousDate);
                                midnightDate.setHours(0, 0, 0, 0);
                                for (let epochForThisMidnight = midnightDate.valueOf(); epochForThisMidnight < event.epoch; epochForThisMidnight += epochDay) {
                                    const dayStart = Math.max(epochForThisMidnight, previousDate);
                                    const dayEnd = Math.min(epochForThisMidnight + epochDay, event.epoch);
                                    const dayRuntime = (dayEnd - dayStart) / epochHour;
                                    const d = datesArray.filter(x => x.epoch === epochForThisMidnight);
                                    if (d.length) {
                                        datesArray.find(x => x.epoch === epochForThisMidnight).runTime += dayRuntime;
                                    } else {
                                        const newRecord = {
                                            epoch: epochForThisMidnight,
                                            runTime: dayRuntime,
                                        };
                                        datesArray.push(newRecord);
                                    }
                                }
                            }
                        }
                        prevStatus = event.event;
                    }

                    datesArray.sort((a, b) => (a.epoch - b.epoch));
                    const formattedData = datesArray.map(x => ([x.epoch, x.runTime]));
                    const formattedDataYtd = [];
                    let total = 0;
                    for (let index = 0; index < datesArray.length; index++) {
                        const element = datesArray[index];
                        total += element.runTime;
                        formattedDataYtd.push([element.epoch, total]);
                    }

                    const highChart = [];
                    highChart.push({ type: 'spline', name: 'Daily Run Time', data: formattedData });
                    highChart.push({ type: 'column', name: 'Run Time YTD', data: formattedDataYtd });
                    returnObject.highChartData = highChart;
                    returnObject.pivotTotals.reverse();
                    getAndStoreDepthSensors(returnObject.highChartData)
                    setSensorSeries([...returnObject.highChartData])
                    setPivotTotals([...returnObject.pivotTotals])
                    setPivotStatus(null)
                }
                else if (8000000 < device.deveui && device.deveui < 9000000) {
                    const rainHourly = [];
                    const rainDaily = [];
                    let total = 0;
                    const rainData = [];
                    const rainYtd = [];
                    const ytd = true;
                    const newObj = [];

                    parsedData.forEach((x) => {
                        rainData.push({ "totalRainfallCount": x[1], "epoch": x[0] })
                    })

                    const loopEnd = rainData[0].epoch;
                    const loopStart = rainData[rainData.length - 1].epoch;
                    const epochHour = 60 * 60 * 1000;
                    const epochDay = epochHour * 24;

                    //Rain Hourly
                    for (let index = loopStart - epochHour; index <= loopEnd; index += epochHour) {
                        const endEpoch = index + epochHour;
                        const startEpoch = index;

                        let rain = 0;
                        if (index === 0) {
                            rainHourly.push([endEpoch, 0]);
                        } else {
                            const r = rainData.filter(x => x.epoch >= startEpoch && x.epoch < endEpoch);

                            r.sort((a, b) => (b.totalRainfallCount - a.totalRainfallCount));
                            let rainMax;
                            let rainMin;
                            switch (r.length) {
                                case 0:
                                    break;
                                case 1:
                                    rain = 0.01;
                                    break;
                                default:
                                    rainMax = r[0].totalRainfallCount;
                                    rainMin = r[r.length - 1].totalRainfallCount;
                                    if (rainMin > rainMax) {
                                        // Battery Replacement
                                        let adjMin;
                                        let adjMax;
                                        const minMax = [];
                                        for (let rIndex = 0; rIndex < r.length; rIndex++) {
                                            const rainTotal = r[rIndex].totalRainfallCount;
                                            if (rainTotal === 1 && !!minMax[1] && minMax[1] > 1 && !minMax[3]) {
                                                minMax[2] = minMax[0];
                                                minMax[3] = minMax[1];
                                                minMax[1] = rainTotal;
                                                minMax[0] = rainTotal;
                                            } else {
                                                minMax[0] = (minMax[0] === undefined || rainTotal < minMax[0]) ? rainTotal : minMax[0];
                                                minMax[1] = (minMax[1] === undefined || rainTotal > minMax[1]) ? rainTotal : minMax[1];
                                            }
                                        }
                                        if (minMax.length) {
                                            adjMin = minMax[1] - rainMin + 1;
                                            adjMax = minMax[3] - minMax[0] + 1;
                                            rain = (adjMax + adjMin) / 100;
                                        } else {
                                        }
                                    } else {
                                        rain = (1 + Math.abs(rainMax - rainMin + 1)) / 100;
                                    }
                            }
                        }
                        rainHourly.push([endEpoch, rain]);
                    }

                    // Rain Daily
                    for (let index = loopStart - epochDay; index <= loopEnd; index += epochDay) {
                        const endEpoch = index + epochDay;
                        const startEpoch = index;
                        let rain = 0;
                        if (index === pltData.getPlaceLinkThing.results[0].link.startDate) {
                            rainHourly.push([endEpoch, 0]);
                        } else {
                            const r = rainData.filter(x => x.epoch > startEpoch && x.epoch <= endEpoch);
                            let rainMax;
                            let rainMin;
                            switch (r.length) {
                                case 0:
                                    break;
                                case 1:
                                    rain = 0.01;
                                    break;
                                default:
                                    r.sort((a, b) => (b.epoch - a.epoch));
                                    rainMax = r[0].totalRainfallCount;
                                    rainMin = r[r.length - 1].totalRainfallCount;
                                    if (rainMin > rainMax) {
                                        // Battery Replacement
                                        let adjMin;
                                        let adjMax;
                                        const minMax = [];
                                        for (let rIndex = 0; rIndex < r.length; rIndex++) {
                                            const rainTotal = r[rIndex].totalRainfallCount;
                                            if (rainTotal === 1 && !!minMax[1] && minMax[1] > 1 && !minMax[3]) {
                                                minMax[2] = minMax[0];
                                                minMax[3] = minMax[1];
                                                minMax[1] = rainTotal;
                                                minMax[0] = rainTotal;
                                            } else {
                                                minMax[0] = (minMax[0] === undefined || rainTotal < minMax[0]) ? rainTotal : minMax[0];
                                                minMax[1] = (minMax[1] === undefined || rainTotal > minMax[1]) ? rainTotal : minMax[1];
                                            }
                                        }
                                        if (minMax.length) {
                                            adjMin = minMax[1] - rainMin + 1;
                                            adjMax = minMax[3] - minMax[0] + 1;
                                            rain = (adjMax + adjMin) / 100;
                                        }
                                    } else {
                                        r.sort((a, b) => (b.totalRainfallCount - a.totalRainfallCount));
                                        rainMax = r[0].totalRainfallCount;
                                        rainMin = r[r.length - 1].totalRainfallCount;
                                        rain = (1 + Math.abs(rainMax - rainMin)) / 100;
                                    }
                            }
                        }
                        rainDaily.push([endEpoch, rain]);
                        total += rain;
                        rainYtd.push([endEpoch, total]);
                    }

                    newObj.push({ "name": "Rain (Hourly)", "type": "spline", "data": rainHourly });
                    newObj.push({ "name": "Rain (Daily)", "data": rainDaily });
                    if (ytd) {
                        newObj.push({ "name": "Rain (YTD)", "data": rainYtd });
                    }
                    getAndStoreDepthSensors(newObj);
                    setSensorSeries([...newObj])

                }
            } else {
                setSensorSeries([]);
            }


        }
    }, [thingPastDataCalled, thingPastData, pltData, device])

    //
    // Device Moisture Chart Set
    useEffect(() => {
        console.log('device in chartCoponent: ', field);
        if (deviceMoistureCalled && deviceMoistureData) {
            const { getDeviceMoistureChart: { results } } = deviceMoistureData;
            const result = results[0] || undefined;

            if (result && result !== null) {
                const parsed = JSON.parse(result);
                // console.log(parsed);
                getAndStoreDepthSensors(parsed);
                setSensorSeries([...parsed]);
            } else if (result === null || !result) {
                setSensorSeries([]);
            }
        }
    }, [deviceMoistureCalled, deviceMoistureData]);

    // Device Rain Chart Set
    useEffect(() => {
        if (deviceRainCalled && deviceRainData) {
            const { getDeviceRainChart: { results } } = deviceRainData;
            const result = results[0] || undefined;
            // console.log(result);
            if (result && result !== null) {
                const parsed = JSON.parse(result);
                // console.log("PARSED", parsed);
                // console.log("STORING DEPTH", parsed);
                getAndStoreDepthSensors(parsed);
                setSensorSeries([...parsed]);
            } else if (result === null || !result) {
                setSensorSeries([]);
            }
        }
    }, [deviceRainCalled, deviceRainData]);

    // Device Pivot Chart Set
    useEffect(() => {
        if (pivotCalled && pivotData) {
            const { getPivotChart: { results } } = pivotData;
            const result = results[0] || undefined;
            // console.log(result);
            if (result && result !== null) {
                // console.log("RESULT", result);
                if (result.highChartData !== null) {
                    const parsed = JSON.parse(result.highChartData);
                    getAndStoreDepthSensors(parsed);
                    setSensorSeries([...parsed]);
                } else {
                    setSensorSeries([]);
                }
                setPivotStatus({ ...result.status });
                setPivotTotals([...result.pivotTotals]);
            } else if (result === null || !result) {
                setSensorSeries([]);
            }
        }
    }, [pivotCalled, pivotData]);


    //
    // Average Moisture Chart Set
    useEffect(() => {
        //for current data
        if (cropYear === "2023") {
            if (averageMoistureCalled && averageMoistureData) {
                if (!previousMoistureData) {
                    setPreviousMoistureData(averageMoistureData);
                }
                const { getDeviceMoistureChartAverage: { results } } = averageMoistureData;
                const aResult = results[0] || undefined;

                if (aResult) {
                    const aParsed = JSON.parse(aResult);
                    setAverageSeries([...aParsed]);
                    if (rerunPreviews) {
                        refetchFieldSensorPreview();
                        setRerunPreviews(false);
                    }
                }
            }
        } else {
            //for past data
            if (probesToAverage !== [] && dataforAvg.length > 0) {
                const newObj = [];
                const averageData = [];
                let depthArray;

                if (device.deveui < 60000000 && device.deveui > 50000000) {
                    depthArray = [4, 8, 12, 16, 24, 32]
                } else if (device.deveui < 4000000 && device.deveui > 3000000) {
                    depthArray = [6, 12, 24, 36];
                }

                if (!previousMoistureData) {
                    setPreviousMoistureData(dataforAvg);
                }

                for (let index = 0; index < dataforAvg[0].data.length; index++) {
                    const epoch = dataforAvg[0].data[index][0];
                    let subTotal = 0;
                    for (let i = 0; i < depthArray.length; i++) {
                        const depth = depthArray[i];
                        if (probesToAverage.includes(depth)) {
                            subTotal += dataforAvg[i].data[index][1];
                        }
                    }
                    averageData.push([epoch, subTotal / probesToAverage.length])
                }
                averageData.sort((a, b) => (a[0] - b[0]));
                newObj.push({ "type": 'spline', "name": 'Average Trend Line', "data": averageData })
                setAverageSeries([...newObj])
                if (rerunPreviews) {
                    refetchFieldSensorPreview();
                    setRerunPreviews(false);
                }
            }
        }
    }, [averageMoistureCalled, averageMoistureData, refetchFieldSensorPreview, rerunPreviews, previousMoistureData, cropYear, dataforAvg, device, probesToAverage]);

    //
    // Listen for Selected Chart to change
    useEffect(() => {
        if (selectedChart !== null & selectedChart !== '' && (selectedChart !== 'rain' && selectedChart !== 'pivot')) {
            if (selectedChart.toLowerCase().indexOf('sensor') > -1) {
                getSensorReadingData();
            } else {
                if (fieldPreview && !instantiated) {
                    setInstantiated(true);
                    getAverageData();
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChart, fieldPreview]);


    if (selectedChart === null) {
        return (null);
    }



    return (
        <>
            <Row>
                <Col xs={12} md={4}>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                        {selectedChart !== "rain" && selectedChart !== "chart" &&
                            <Form.Control
                                as="select"
                                onChange={e => handleChartSelect(e)}
                                value={selectedChart || ''}
                            >
                                {(deviceType.toLowerCase().indexOf('watermark') > -1) &&
                                    <option value="sensorReading">Plant Available Water (% / each sensor)</option>
                                }
                                {(deviceType.toLowerCase().indexOf('other') > -1) &&
                                    <option value="sensorReading">Raw Sensor Reading (centibars / each sensor)</option>
                                }
                                {(deviceType.toLowerCase().indexOf('capacitance') > -1) &&
                                    <option value="sensorReading">Raw Sensor Reading (SF / each sensor)</option>
                                }
                                <option value="avgTrendline">Average Trend Line</option>
                            </Form.Control>
                        }
                    </Form.Group>
                </Col>
                <Col xs={12} md={8} className="align-right">
                    {selectedChart === 'sensorReading' ? (
                        <Button
                            variant="primary"
                            size="small"
                            onClick={() => setModalShow(true)}
                        >
                            Device Settings
                        </Button>
                    ) : (null)}
                </Col>

            </Row>
            <Row>
                <Col xs={12}>
                    <div className="chart-outer-wrapper">
                        {(selectedChart === 'sensorReading' || selectedChart === 'rain' || selectedChart === 'pivot') ? (
                            sensorSeries === null ? (
                                <div className="chart-spinner"><Spinner>...Getting Chart Data</Spinner></div>
                            ) : (
                                sensorSeries.length === 0 || (selectedChart === 'sensorReading' && sensorSeries.length < 4) ? (
                                    <div className="chart-spinner"><p>No results returned</p></div>
                                ) : (
                                    <SensorReadingChart
                                        bindTo={`chart-${field.guid}`}
                                        deviceType={deviceType}
                                        series={sensorSeries}
                                        plt={plt}
                                    />
                                )
                            )
                        ) : (
                            averageSeries === null ? (
                                <div className="chart-spinner"><Spinner>...Getting Chart Data</Spinner></div>
                            ) : (
                                <AverageTrendLineChart
                                    bindTo={`chart-${field.guid}`}
                                    device={device}
                                    deviceType={deviceType}
                                    series={averageSeries}
                                    depthSensors={depthSensors}
                                    handleFieldSettings={handleFieldSettings}
                                    getAverageData={getAverageData}
                                    fieldPreview={fieldPreview}
                                    plt={plt}
                                />
                            )
                        )}
                    </div>
                </Col>
            </Row>
            {
                selectedChart === 'sensorReading' &&
                <Card className="soil-moisture-accordion-card">
                    <Accordion activeKey={etsOpen}>
                        <AccordionHeader
                            eventKey={'ets-' + field.guid}
                            onClick={() => handleEtsOpen('ets')}
                            isOpen={etsOpen}
                            title="ET Summary"
                        />
                        <Accordion.Collapse eventKey={'ets-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <EtSummary plt={plt} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion activeKey={irOpen}>
                        <AccordionHeader
                            eventKey={'ir-' + field.guid}
                            onClick={() => handleIrOpen('ir')}
                            isOpen={irOpen}
                            title="Irrigation Recommendations"
                        />
                        <Accordion.Collapse eventKey={'ir-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <IrrigationRecommendations
                                    acreInches={calculatorData.acreInches}
                                    deviceType={deviceType}
                                    fieldPreview={fieldPreview}
                                    handleCalculatorInputChange={handleCalculatorInputChange}
                                    plt={plt}
                                    visible={tileToggle.irrigationRecommendations}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion activeKey={specsOpen}>
                        <AccordionHeader
                            eventKey={'irs-' + field.guid}
                            onClick={() => handleSpecsOpen('irs')}
                            isOpen={specsOpen}
                            title="Irrigation Specs"
                        />
                        <Accordion.Collapse eventKey={'irs-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <IrrigationSpecs
                                    deviceType={deviceType}
                                    fieldPreview={fieldPreview}
                                    plt={plt}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion activeKey={calcOpen}>
                        <AccordionHeader
                            eventKey={'irc-' + field.guid}
                            onClick={() => handleCalcOpen('irc')}
                            isOpen={calcOpen}
                            title="Irrigation Calculator"
                        />
                        <Accordion.Collapse eventKey={'irc-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <IrrigationCalculator
                                    // acreInches={calculatorData.acreInches}
                                    // daysToApplyIrrigation={calculatorData.daysToApplyIrrigation}
                                    // handleCalculatorInputChange={handleCalculatorInputChange}
                                    // irrigatedAcres={calculatorData.irrigatedAcres}
                                    // visible={tileToggle.irrigationCalculator}
                                    // wellCapacity={calculatorData.wellCapacity}
                                    handleFieldSettings={handleFieldSettings}
                                    plt={plt}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </Card>
            }
            {
                selectedChart === "pivot" &&
                <Card className="pivot-monitor-accordion-card">
                    <Accordion activeKey={pivotOpen}>
                        <AccordionHeader
                            eventKey={'ps-' + field.guid}
                            onClick={() => handlePivotOpen('ps')}
                            isOpen={pivotOpen}
                            title="Pivot Status"
                        />
                        <Accordion.Collapse eventKey={'ps-' + field.guid} mountOnEnter unmountOnExit>
                            <Card.Body>
                                <div>
                                    <Row style={{ "marginTop": "0.2rem" }}>
                                        <Col sm="12">
                                            {pivotStatus !== null &&
                                                <Table responsive>
                                                    <thead style={{ "textAlign": "center", "fontSize": "0.75rem" }}>
                                                        <tr style={{ "lineHeight": "0.5rem" }}>
                                                            <td style={{ "border": "1px solid lightgray" }}>Prev. Bearing</td>
                                                            <td style={{ "border": "1px solid lightgray" }}>Prev. Direction</td>
                                                            <td style={{ "border": "1px solid lightgray" }}>Prev. Status</td>
                                                            <td style={{ "border": "1px solid lightgray" }}>Bearing</td>
                                                            <td style={{ "border": "1px solid lightgray" }}>Direction</td>
                                                            <td style={{ "border": "1px solid lightgray" }}>Status</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ "textAlign": "center", "fontSize": "0.75rem" }}>
                                                        <tr style={{ "lineHeight": "0.5rem" }}>
                                                            <td style={{ "paddingLeft": "0px", "paddingRight": "0px", "border": "1px solid lightgray" }}>
                                                                {pivotStatus.previousBearing || "No Data Found"}</td>
                                                            <td style={{ "paddingLeft": "0px", "paddingRight": "0px", "border": "1px solid lightgray" }}>
                                                                {pivotStatus.previousCompass || "No Data Found"}</td>
                                                            <td style={{ "paddingLeft": "0px", "paddingRight": "0px", "border": "1px solid lightgray" }}>
                                                                {pivotStatus.previousStatus || "No Data Found"}</td>
                                                            <td style={{ "paddingLeft": "0px", "paddingRight": "0px", "border": "1px solid lightgray" }}>
                                                                {pivotStatus.currentBearing || "No Data Found"}</td>
                                                            <td style={{ "paddingLeft": "0px", "paddingRight": "0px", "border": "1px solid lightgray" }}>
                                                                {pivotStatus.currentCompass || "No Data Found"}</td>
                                                            <td style={{ "paddingLeft": "0px", "paddingRight": "0px", "border": "1px solid lightgray" }}>
                                                                {pivotStatus.currentStatus || "No Data Found"}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            }
                                        </Col>
                                    </Row>
                                    <Row style={{ "marginTop": "0.2rem" }}>
                                        <Col sm="12">
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Date/Time</th>
                                                        <th>Event</th>
                                                        <th>Hrs Forward</th>
                                                        <th>Hrs Reverse</th>
                                                        <th>Hrs Off</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pivotTotals !== null && pivotTotals.length > 0 &&
                                                        pivotTotals.map((total, index) => {
                                                            return (

                                                                <tr key={index}>
                                                                    <td>{total.dateTime}</td>
                                                                    <td>{total.event}</td>
                                                                    <td>{total.hoursForward.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                                    <td>{total.hoursReverse.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                                    <td>{total.hoursOff.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                </Card >
            }

            <SettingsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                handleDeviceSettings={handleDeviceSettings}
                plt={plt}
            />
        </>
    );
};

export default ChartComponent;
